export default class BrandPlacesFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.establishmentToken = "";
    this.creationDateTime = "";
    this.lastUpdateDateTime = "";
    this.createdByUserToken = "";
    this.lastUpdatedByUserToken = "";
    this.textSearch = "";
    this.activityTypeToken = "";
    this.brandToken = "";
    this.countryToken = "";
    this.governorateToken = "";
    this.cityToken = "";
    this.districtToken = "";
  }
  fillData(data) {
    this.establishmentToken = data.establishmentToken;
    this.creationDateTime = data.creationDateTime;
    this.lastUpdateDateTime = data.lastUpdateDateTime;
    this.createdByUserToken = data.createdByUserToken;
    this.lastUpdatedByUserToken = data.lastUpdatedByUserToken;
    this.textSearch = data.textSearch;
    this.activityTypeToken = data.activityTypeToken;
    this.brandToken = data.brandToken;
    this.countryToken = data.countryToken;
    this.governorateToken = data.governorateToken;
    this.cityToken = data.cityToken;
    this.districtToken = data.districtToken;
  }
}
