<template>
  <b-modal id="BrandPlaceInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/brand-places.svg" class="icon-lg" />
        {{ $t("brandPlaces.data") }}
      </h3>
    </template>
    <div class="">
      <div class="my-card">
        <div class="row">
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="
              brandPlace.brandData.activityTypeData.activityTypeNameCurrent
            "
            :title="$t('activityTypes.name')"
            :imgName="'activity-types.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="brandPlace.brandData.brandNameCurrent"
            :title="$t('brands.name')"
            :imgName="'brand.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="brandPlace.brandPlaceNameAr"
            :title="$t('brandPlaces.nameAr')"
            :imgName="'brand-places.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="brandPlace.brandPlaceNameEn"
            :title="$t('brandPlaces.nameEn')"
            :imgName="'brand-places.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="brandPlace.brandPlaceAddressAr"
            :title="$t('addressAr')"
            :imgName="'address.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="brandPlace.brandPlaceAddressEn"
            :title="$t('addressEn')"
            :imgName="'address.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="brandPlace.brandPlaceLatitude"
            :title="$t('latitude')"
            :imgName="'latitude.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="brandPlace.brandPlacelongitude"
            :title="$t('longitude')"
            :imgName="'longitude.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="brandPlace.fullCode"
            :title="$t('code')"
            :imgName="'code.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="brandPlace.brandPlaceTaxNumber"
            :title="$t('brandPlaces.taxNumber')"
            :imgName="'number.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-8 phone-number'"
            :value="brandPlace.brandPlacePhone1WithCC"
            :title="$t('phone1')"
            :imgName="'phone.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-4'"
            :value="
              brandPlace.brandPlacePhone1Status == true
                ? $t('activeStatus')
                : $t('disActiveStatus')
            "
            :title="$t('brandPlaces.phoneStatus')"
            :imgName="'type.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-8 phone-number'"
            :value="brandPlace.brandPlacePhone2WithCC"
            :title="$t('phone2')"
            :imgName="'phone.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-4'"
            :value="
              brandPlace.brandPlacePhone2Status == true
                ? $t('activeStatus')
                : $t('disActiveStatus')
            "
            :title="$t('brandPlaces.phoneStatus')"
            :imgName="'type.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-8 phone-number'"
            :value="brandPlace.brandPlacePhone3WithCC"
            :title="$t('phone3')"
            :imgName="'phone.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-4'"
            :value="
              brandPlace.brandPlacePhone3Status == true
                ? $t('activeStatus')
                : $t('disActiveStatus')
            "
            :title="$t('brandPlaces.phoneStatus')"
            :imgName="'type.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-8 phone-number'"
            :value="brandPlace.brandPlacePhone4WithCC"
            :title="$t('phone4')"
            :imgName="'phone.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-4'"
            :value="
              brandPlace.brandPlacePhone4Status == true
                ? $t('activeStatus')
                : $t('disActiveStatus')
            "
            :title="$t('brandPlaces.phoneStatus')"
            :imgName="'type.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-8'"
            :value="brandPlace.mapLink"
            :title="$t('brandPlaces.mapLink')"
            :imgName="'location.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-4'"
            :value="
              brandPlace.mapLinkStatus == true
                ? $t('activeStatus')
                : $t('disActiveStatus')
            "
            :title="$t('brandPlaces.linkStatus')"
            :imgName="'type.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-8'"
            :value="brandPlace.faceBookLink"
            :title="$t('brandPlaces.faceBookLink')"
            :imgName="'facebook.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-4'"
            :value="
              brandPlace.faceBookLinkStatus == true
                ? $t('activeStatus')
                : $t('disActiveStatus')
            "
            :title="$t('brandPlaces.linkStatus')"
            :imgName="'type.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-8'"
            :value="brandPlace.twitterLink"
            :title="$t('brandPlaces.twitterLink')"
            :imgName="'twitter.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-4'"
            :value="
              brandPlace.twitterLinkStatus == true
                ? $t('activeStatus')
                : $t('disActiveStatus')
            "
            :title="$t('brandPlaces.linkStatus')"
            :imgName="'type.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-8'"
            :value="brandPlace.instagramLink"
            :title="$t('brandPlaces.instagramLink')"
            :imgName="'instagram.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-4'"
            :value="
              brandPlace.instagramLinkStatus == true
                ? $t('activeStatus')
                : $t('disActiveStatus')
            "
            :title="$t('brandPlaces.linkStatus')"
            :imgName="'type.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-8'"
            :value="brandPlace.webSiteLink"
            :title="$t('brandPlaces.webSiteLink')"
            :imgName="'link.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-4'"
            :value="
              brandPlace.webSiteLinkStatus == true
                ? $t('activeStatus')
                : $t('disActiveStatus')
            "
            :title="$t('brandPlaces.linkStatus')"
            :imgName="'type.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-12'"
            :value="brandPlace.brandPlaceNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { timeToLang } from "@/utils/functions";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["brandPlace"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
  },
};
</script>

<style scoped lang="scss"></style>
