<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="brandPlaces.brandPlacesData">
      <div class="row">
        <BrandPlaceCard
          v-for="(brandPlace, index) in brandPlaces.brandPlacesData"
          :key="index"
          :defaultImg="brandPlaces.brandPlace.defaultImg"
          v-on:setBrandPlaceData="brandPlaces.brandPlace.fillData($event)"
          :brandPlace="brandPlace"
        />
      </div>

      <ActionsData :actionsData="brandPlaces.brandPlace" />
      <BrandPlaceInfo :brandPlace="brandPlaces.brandPlace" />
      <BrandPlaceDelete
        :brandPlace="brandPlaces.brandPlace"
        v-on:refresh="getAllBrandPlaces()"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="brandPlaces.pagination"
        v-on:changePage="changePage"
      />
    </div>

    <BrandPlaceFloatBtns
      :theFilterData="brandPlaces.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import ActionsData from "@/components/general/ActionsData.vue";
import BrandPlaceCard from "@/components/brandPlaces/BrandPlaceCard.vue";
import BrandPlaceInfo from "@/components/brandPlaces/BrandPlaceInfo.vue";
import BrandPlaceDelete from "@/components/brandPlaces/BrandPlaceDelete.vue";
import BrandPlaceFloatBtns from "@/components/brandPlaces/BrandPlaceFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import BrandPlaces from "@/models/brandPlaces/BrandPlaces";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    ActionsData,
    BrandPlaceCard,
    BrandPlaceInfo,
    BrandPlaceDelete,
    BrandPlaceFloatBtns,
    Pagination,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  props: ["activityTypeToken", "brandToken"],
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      brandPlaces: new BrandPlaces(),
    };
  },
  methods: {
    changePage(page) {
      this.brandPlaces.pagination.selfPage = page;
      this.getAllBrandPlaces();
    },
    search(data) {
      this.brandPlaces.filterData.fillData(data);
      this.brandPlaces.brandPlacesData = [];
      this.getAllBrandPlaces();
    },
    async getAllBrandPlaces() {
      this.isLoading = true;
      try {
        const response = await this.brandPlaces.brandPlace.getAllBrandPlaces(
          this.language,
          this.userAuthorizeToken,
          this.brandPlaces.pagination,
          this.brandPlaces.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.brandPlaces.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.brandPlaces.brandPlacesData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.brandPlaces.brandPlacesData = null;
          this.$store.dispatch("logoutUser");
        } else {
          this.brandPlaces.brandPlacesData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.brandPlaces.brandPlacesData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
    setFilter() {
      this.brandPlaces.filterData.activityTypeToken = this.activityTypeToken;
      this.brandPlaces.filterData.brandToken = this.brandToken;
    },
  },
  async created() {
    this.setFilter();
    this.getAllBrandPlaces();
  },
};
</script>
