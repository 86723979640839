import axios from "axios";
import BaseEntityModel from "./../general/BaseEntityModel";
import Brand from "./../brands/Brand";
import BrandPlacesFilter from "./BrandPlacesFilter";
import BrandPlaceImg from "@/assets/images/brand-places.svg";
import { PAGE_SIZE, STATUS, BASE_URL } from "@/utils/constants";
import i18n from "@/i18n";
import store from "@/store";
import { numberToEn } from "./../../utils/functions";

export default class BrandPlace extends BaseEntityModel {
  defaultImg = BrandPlaceImg;
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.brandPlaceToken = "";
    this.brandPlaceTypeToken = "";
    this.brandPlaceTypeNameCurrent = "";
    this.brandPlaceTypeNameEn = "";
    this.brandPlaceTypeNameAr = "";
    this.brandPlaceNameCurrent = "";
    this.brandPlaceNameEn = "";
    this.brandPlaceNameAr = "";
    this.brandPlaceAddressCurrent = "";
    this.brandPlaceAddressAr = "";
    this.brandPlaceAddressEn = "";
    this.brandPlacePhone1 = "";
    this.brandPlacePhone1CountryCodeName = "EG";
    this.brandPlacePhone1CountryCode = "+20";
    this.brandPlacePhone1WithCC = "";
    this.brandPlacePhone1Status = "";
    this.brandPlacePhone1CCWithCCName = "EG +20";
    this.brandPlacePhone2 = "";
    this.brandPlacePhone2CountryCodeName = "EG";
    this.brandPlacePhone2CountryCode = "+20";
    this.brandPlacePhone2WithCC = "";
    this.brandPlacePhone2Status = "";
    this.brandPlacePhone2CCWithCCName = "EG +20";
    this.brandPlacePhone3 = "";
    this.brandPlacePhone3CountryCodeName = "EG";
    this.brandPlacePhone3CountryCode = "+20";
    this.brandPlacePhone3WithCC = "";
    this.brandPlacePhone3Status = "";
    this.brandPlacePhone3CCWithCCName = "EG +20";
    this.brandPlacePhone4 = "";
    this.brandPlacePhone4CountryCodeName = "EG";
    this.brandPlacePhone4CountryCode = "+20";
    this.brandPlacePhone4WithCC = "";
    this.brandPlacePhone4Status = "";
    this.brandPlacePhone4CCWithCCName = "EG +20";
    this.brandPlaceTaxNumber = "";
    this.brandPlaceLatitude = "";
    this.brandPlacelongitude = "";
    this.brandPlaceImagePath = "";
    this.brandPlaceNotes = "";
    this.brandPlaceArchiveStatus = "";
    this.mapIconLink = "";
    this.mapLink = "";
    this.mapLinkStatus = "";
    this.faceBookIconLink = "";
    this.faceBookLink = "";
    this.faceBookLinkStatus = "";
    this.twitterIconLink = "";
    this.twitterLink = "";
    this.twitterLinkStatus = "";
    this.instagramIconLink = "";
    this.instagramLink = "";
    this.instagramLinkStatus = "";
    this.webSiteIconLink = "";
    this.webSiteLink = "";
    this.webSiteLinkStatus = "";
    this.brandToken = "";
    this.brandData = new Brand();
  }
  fillData(data) {
    if (data) {
      this.fillDataBaseEntityModelBaseEntityModel(data);
      this.brandPlaceToken = data.brandPlaceToken;
      this.brandPlaceTypeToken = data.brandPlaceTypeToken;
      this.brandPlaceTypeNameCurrent = data.brandPlaceTypeNameCurrent;
      this.brandPlaceTypeNameEn = data.brandPlaceTypeNameEn;
      this.brandPlaceTypeNameAr = data.brandPlaceTypeNameAr;
      this.brandPlaceNameCurrent = data.brandPlaceNameCurrent;
      this.brandPlaceNameEn = data.brandPlaceNameEn;
      this.brandPlaceNameAr = data.brandPlaceNameAr;
      this.brandPlaceAddressCurrent = data.brandPlaceAddressCurrent;
      this.brandPlaceAddressAr = data.brandPlaceAddressAr;
      this.brandPlaceAddressEn = data.brandPlaceAddressEn;
      this.brandPlacePhone1 = data.brandPlacePhone1;
      this.brandPlacePhone1CountryCodeName =
        data.brandPlacePhone1CountryCodeName;
      this.brandPlacePhone1CountryCode = data.brandPlacePhone1CountryCode;
      this.brandPlacePhone1WithCC = data.brandPlacePhone1WithCC;
      this.brandPlacePhone1Status = data.brandPlacePhone1Status;
      this.brandPlacePhone2 = data.brandPlacePhone2;
      this.brandPlacePhone2CountryCodeName =
        data.brandPlacePhone2CountryCodeName;
      this.brandPlacePhone2CountryCode = data.brandPlacePhone2CountryCode;
      this.brandPlacePhone2WithCC = data.brandPlacePhone2WithCC;
      this.brandPlacePhone2Status = data.brandPlacePhone2Status;
      this.brandPlacePhone3 = data.brandPlacePhone3;
      this.brandPlacePhone3CountryCodeName =
        data.brandPlacePhone3CountryCodeName;
      this.brandPlacePhone3CountryCode = data.brandPlacePhone3CountryCode;
      this.brandPlacePhone3WithCC = data.brandPlacePhone3WithCC;
      this.brandPlacePhone3Status = data.brandPlacePhone3Status;
      this.brandPlacePhone4 = data.brandPlacePhone4;
      this.brandPlacePhone4CountryCodeName =
        data.brandPlacePhone4CountryCodeName;
      this.brandPlacePhone4CountryCode = data.brandPlacePhone4CountryCode;
      this.brandPlacePhone4WithCC = data.brandPlacePhone4WithCC;
      this.brandPlacePhone4Status = data.brandPlacePhone4Status;
      this.brandPlaceTaxNumber = data.brandPlaceTaxNumber;
      this.brandPlaceLatitude = data.brandPlaceLatitude;
      this.brandPlacelongitude = data.brandPlacelongitude;
      this.brandPlaceImagePath = data.brandPlaceImagePath;
      this.brandPlaceNotes = data.brandPlaceNotes;
      this.brandPlaceArchiveStatus = data.brandPlaceArchiveStatus;
      this.mapIconLink = data.mapIconLink;
      this.mapLink = data.mapLink;
      this.mapLinkStatus = data.mapLinkStatus;
      this.faceBookIconLink = data.faceBookIconLink;
      this.faceBookLink = data.faceBookLink;
      this.faceBookLinkStatus = data.faceBookLinkStatus;
      this.twitterIconLink = data.twitterIconLink;
      this.twitterLink = data.twitterLink;
      this.twitterLinkStatus = data.twitterLinkStatus;
      this.instagramIconLink = data.instagramIconLink;
      this.instagramLink = data.instagramLink;
      this.instagramLinkStatus = data.instagramLinkStatus;
      this.webSiteIconLink = data.webSiteIconLink;
      this.webSiteLink = data.webSiteLink;
      this.webSiteLinkStatus = data.webSiteLinkStatus;
      this.brandToken = data.brandToken;
      this.brandData.fillData(data.brandData);
      this.brandPlacePhone1CCWithCCName =
        data.brandPlacePhone1CountryCodeName &&
        data.brandPlacePhone1CountryCodeName
          ? `${data.brandPlacePhone1CountryCodeName} ${data.brandPlacePhone1CountryCode}`
          : "";
      this.brandPlacePhone2CCWithCCName =
        data.brandPlacePhone2CountryCodeName &&
        data.brandPlacePhone2CountryCodeName
          ? `${data.brandPlacePhone2CountryCodeName} ${data.brandPlacePhone2CountryCode}`
          : "";
      this.brandPlacePhone3CCWithCCName =
        data.brandPlacePhone3CountryCodeName &&
        data.brandPlacePhone3CountryCodeName
          ? `${data.brandPlacePhone3CountryCodeName} ${data.brandPlacePhone3CountryCode}`
          : "";
      this.brandPlacePhone4CCWithCCName =
        data.brandPlacePhone4CountryCodeName &&
        data.brandPlacePhone4CountryCodeName
          ? `${data.brandPlacePhone4CountryCodeName} ${data.brandPlacePhone4CountryCode}`
          : "";
    }
  }

  async getAllBrandPlaces(
    language,
    userAuthorizeToken,
    pagination,
    filterData
  ) {
    return await axios.get(
      `/api/BrandPlaces/GetAllBrandPlaces?language=${language}&userAuthorizeToken=${userAuthorizeToken}&paginationStatus=true&pageSize=${PAGE_SIZE}&page=${pagination.selfPage}&filterStatus=true&activityTypeToken=${filterData.activityTypeToken}&brandToken=${filterData.brandToken}&countryToken=${filterData.countryToken}&governorateToken=${filterData.governorateToken}&cityToken=${filterData.cityToken}&districtToken=${filterData.districtToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getBrandPlaceDialog(
    language,
    userAuthorizeToken,
    filterData = new BrandPlacesFilter()
  ) {
    let options = [];
    try {
      let response = await axios.get(
        `/api/BrandPlaces/GetBrandPlaceDialog?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&activityTypeToken=${filterData.activityTypeToken}&brandToken=${filterData.brandToken}&countryToken=${filterData.countryToken}&governorateToken=${filterData.governorateToken}&cityToken=${filterData.cityToken}&districtToken=${filterData.districtToken}&textSearch=${filterData.textSearch}`
      );
      const itemsData = response.data.itemsData;
      if (response.data.status == STATUS.SUCCESS) {
        for (let item in itemsData) {
          options.push({
            value: itemsData[item]["itemToken"],
            text: itemsData[item]["itemName"],
            image: itemsData[item]["itemImagePath"]
              ? BASE_URL + itemsData[item]["itemImagePath"]
              : BrandPlaceImg,
          });
        }
      } else if (response.data.status == STATUS.INVALID_TOKEN) {
        store.dispatch("logoutUser");
      } else {
        options.push({
          value: "",
          text: response.data.msg,
          image: BrandPlaceImg,
        });
      }
    } catch (error) {
      options.push({
        value: "",
        text: i18n.t("errorCatch"),
        image: BrandPlaceImg,
      });
    }
    return options;
  }

  async getBrandPlaceDetails(language, userAuthorizeToken) {
    return await axios.get(
      `/api/BrandPlaces/GetBrandPlaceDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&token=${this.brandPlaceToken}`
    );
  }

  async addOrUpdateBrandPlace(language, userAuthorizeToken) {
    var formData = new FormData();
    formData.append("mediaFile", this.mediaFile);
    formData.append("language", language);
    formData.append("userAuthorizeToken", userAuthorizeToken);
    formData.append("brandPlaceToken", this.brandPlaceToken);
    formData.append("brandToken", this.brandToken);
    formData.append("fullCode", this.fullCode);

    formData.append("brandPlaceNameEn", this.brandPlaceNameEn);
    formData.append("brandPlaceNameAr", this.brandPlaceNameAr);

    formData.append("brandPlaceAddressAr", this.brandPlaceAddressAr);
    formData.append("brandPlaceAddressEn", this.brandPlaceAddressEn);

    formData.append("brandPlacePhone1", numberToEn(this.brandPlacePhone1));
    formData.append(
      "brandPlacePhone1CountryCodeName",
      this.brandPlacePhone1CountryCodeName
    );
    formData.append(
      "brandPlacePhone1CountryCode",
      this.brandPlacePhone1CountryCode
    );
    formData.append("brandPlacePhone1Status", this.brandPlacePhone1Status);

    formData.append("brandPlacePhone2", numberToEn(this.brandPlacePhone2));
    formData.append(
      "brandPlacePhone2CountryCodeName",
      this.brandPlacePhone2CountryCodeName
    );
    formData.append(
      "brandPlacePhone2CountryCode",
      this.brandPlacePhone2CountryCode
    );
    formData.append("brandPlacePhone2WithCC", this.brandPlacePhone2WithCC);
    formData.append("brandPlacePhone2Status", this.brandPlacePhone2Status);
    formData.append("brandPlacePhone3", numberToEn(this.brandPlacePhone3));
    formData.append(
      "brandPlacePhone3CountryCodeName",
      this.brandPlacePhone3CountryCodeName
    );
    formData.append(
      "brandPlacePhone3CountryCode",
      this.brandPlacePhone3CountryCode
    );
    formData.append("brandPlacePhone3Status", this.brandPlacePhone3Status);
    formData.append("brandPlacePhone4", numberToEn(this.brandPlacePhone4));
    formData.append(
      "brandPlacePhone4CountryCodeName",
      this.brandPlacePhone4CountryCodeName
    );
    formData.append(
      "brandPlacePhone4CountryCode",
      this.brandPlacePhone4CountryCode
    );
    formData.append("brandPlacePhone4Status", this.brandPlacePhone4Status);

    formData.append("mapLink", this.mapLink);
    formData.append("mapLinkStatus", this.mapLinkStatus);
    formData.append("faceBookLink", this.faceBookLink);
    formData.append("faceBookLinkStatus", this.faceBookLinkStatus);
    formData.append("twitterLink", this.twitterLink);
    formData.append("twitterLinkStatus", this.twitterLinkStatus);
    formData.append("instagramLink", this.instagramLink);
    formData.append("instagramLinkStatus", this.instagramLinkStatus);
    formData.append("webSiteLink", this.webSiteLink);
    formData.append("webSiteLinkStatus", this.webSiteLinkStatus);

    formData.append("brandPlaceTaxNumber", this.brandPlaceTaxNumber);

    formData.append("brandPlaceLatitude", this.brandPlaceLatitude);
    formData.append("brandPlacelongitude", this.brandPlacelongitude);

    formData.append("brandPlaceNotes", this.brandPlaceNotes);

    if (this.brandPlaceToken == "" || this.brandPlaceToken == undefined) {
      return await axios.post(`/api/BrandPlaces/AddBrandPlace`, formData);
    } else {
      return await axios.post(`/api/BrandPlaces/UpdateBrandPlace`, formData);
    }
  }

  async archiveBrandPlace(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      token: this.brandPlaceToken,
      archiveStatus: true,
    };

    return await axios.post(`/api/BrandPlaces/ArchiveBrandPlace`, data);
  }
}
