import PaginationData from "@/models/general/PaginationData";
import BrandPlace from "./BrandPlace";
import BrandPlacesFilter from "./BrandPlacesFilter";

export default class BrandPlaces {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.brandPlacesData = [];
    this.brandPlace = new BrandPlace();
    this.filterData = new BrandPlacesFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.pagination);
    this.brandPlacesData = data.brandPlacesData;
  }
}
