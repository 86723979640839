<template>
  <div class="flip-card col-12 col-sm-6 col-md-4 col-lg-3">
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <img :src="imgSrc" :onerror="`this.src='${defaultImg}'`" alt="Image" />
        <div class="flip-card-title">
          <h4 class="cut-1line" :title="brandPlace.brandPlaceNameCurrent">
            {{ isDataExist(brandPlace.brandPlaceNameCurrent) }}
          </h4>
        </div>
      </div>
      <div class="flip-card-back">
        <div class="flip-card-title-top">
          <h6 class="cut-1line">
            {{ isDataExist(brandPlace.fullCode) }}
          </h6>
        </div>
        <div class="flip-card-back-icons">
          <button
            v-b-modal.BrandPlaceInfo
            class="btn p-0"
            :title="$t('info')"
            @click="setBrandPlaceData()"
          >
            <img src="@/assets/images/info.svg" class="icon-lg" />
          </button>
          <router-link
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeBrandPlaces.update
              )
            "
            :to="{
              name: 'BrandPlaceEdit',
              params: {
                brandPlaceToken: brandPlace.brandPlaceToken,
              },
            }"
            :title="$t('edit')"
          >
            <img src="@/assets/images/pencil.svg" class="icon-lg" />
          </router-link>
          <button
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeBrandPlaces.archive
              )
            "
            v-b-modal.BrandPlaceDelete
            class="btn p-0"
            :title="$t('delete')"
            @click="setBrandPlaceData()"
          >
            <img src="@/assets/images/trash.svg" class="icon-lg" />
          </button>
          <button
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeBrandPlaces.update
              )
            "
            v-b-modal.ActionsData
            class="btn p-0"
            :title="$t('actionsData.modelName')"
            @click="setBrandPlaceData()"
          >
            <img src="@/assets/images/actions-data.svg" class="icon-lg" />
          </button>
        </div>
        <div class="flip-card-title">
          <h4 class="cut-1line" :title="brandPlace.brandPlaceNameCurrent">
            {{ isDataExist(brandPlace.brandPlaceNameCurrent) }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkPrivilege } from "@/utils/functions";
import { BASE_URL } from "@/utils/constants";
import { isDataExist } from "@/utils/functions";

export default {
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["brandPlace", "defaultImg"],
  methods: {
    setBrandPlaceData() {
      this.$emit("setBrandPlaceData", this.brandPlace);
    },
    isDataExist,
    checkPrivilege,
  },
  computed: {
    ...mapGetters(["usersPrivilegeData"]),
    imgSrc: function () {
      return BASE_URL + this.brandPlace.brandPlaceImagePath;
    },
  },
};
</script>

<style lang="scss"></style>
